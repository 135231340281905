<template>
  <div class="page-servicePage">
    <service-page @paySuccess="handlePaySuccess"></service-page>
    <service-banner></service-banner>
  </div>
</template>

<script>
import serviceBanner from '@/components/serviceBanner';
import servicePage from '@/components/servicePage';

export default {
  components: {
    serviceBanner,
    servicePage
  },
  methods: {
    handlePaySuccess() {
      setTimeout(() => {
        this.$router.replace('/user');
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.module-serviceBanner {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
