<template>
  <div class="page-orderList">
    <div class="list-tabs">
      <van-tabs v-model="tabIdx" class="tabs" @change="handleTabsChange">
        <van-tab title="普通订单"></van-tab>
        <van-tab title="服务订单"></van-tab>
      </van-tabs>
    </div>
    <van-tabs
      v-model="filterIdx"
      class="filter-tabs"
      @change="handleTabsChange"
    >
      <van-tab class="filter-tab" title="未开奖"></van-tab>
      <van-tab class="filter-tab" title="中奖"></van-tab>
      <van-tab class="filter-tab" title="未中奖"></van-tab>
    </van-tabs>
    <div class="order-list">
      <template v-if="orderList.length">
        <router-link
          :to="!tabIdx ? `/user/orderDetail?orderId=${item.id}` : ''"
          v-for="item in orderList"
          :key="item.id"
          class="order-item"
        >
          <order-item :lotteryData="item"></order-item>
        </router-link>
      </template>
      <div v-else-if="!loading" class="order-empty">
        <p>没有更多{{ filterArr[filterIdx].text }}数据了</p>
      </div>
    </div>
  </div>
</template>

<script>
import orderItem from '@/components/lotteryItem';

export default {
  components: {
    orderItem
  },
  data() {
    return {
      loading: true,
      tabIdx: 0,
      tabsArr: ['普通订单', 'VIP订单'],
      filterIdx: 0,
      filterArr: [
        { text: '未开奖', value: 0 },
        { text: '中奖', value: 1 },
        { text: '未中奖', value: 2 }
      ],
      filterShow: false,
      orderList: []
    };
  },
  methods: {
    getOrderList() {
      this.loading = true;
      this.orderList = [];
      let api = this.tabIdx ? 'unlockLog/index' : 'order/index';
      let status = this.filterArr[this.filterIdx].value;
      this.$http(api, { status, page: 1, pageSize: 999 })
        .then(res => {
          this.setOrderList(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setOrderList(data) {
      this.orderList = data.data.map(e => {
        let { forecast } = e;
        let obj = {
          id: e.id,
          forecastId: e.forecastId,
          no: e.no,
          checkNum: forecast.checkNum,
          date: forecast.date,
          rightNumber: forecast.rightNumber,
          forecastType: forecast.type,
          forecastStatus: forecast.status
        };
        if (e.forecast.type === 10) {
          obj.forecastList = [
            forecast.position1,
            forecast.position2,
            forecast.position3,
            forecast.position4,
            forecast.position5
          ];
        } else {
          obj.forecastList = forecast.mapArray;
        }
        return obj;
      });
    },

    handleTabsChange() {
      this.getOrderList();
    }
  },
  activated() {
    this.getOrderList();
  },

  deactivated() {
    if (this.$route.name === 'orderDetail') return false;
    this.filterIdx = 0;
    this.tabIdx = 0;
    this.orderList = [];
  }
};
</script>

<style lang="scss" scoped>
.page-orderList {
  padding-top: 252px;
}

.list-tabs {
  z-index: 4;
  position: fixed;
  top: 0;
  padding: 30px 0 30px;
  width: 100%;
  height: 158px;
  background: #fff;

  .tabs {
    margin: 0 auto 30px;
    padding: 5px 10px;
    width: 690px;
    height: 98px;
    border: 2px solid #ff7b5a;
    border-radius: 49px;
    overflow: hidden;
    background: #fff;

    &::v-deep {
      .van-tabs__line {
        bottom: 36px;
        width: 334px;
        height: 78px;
        border-radius: 39px;
        background: linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
      }

      .van-tab {
        z-index: 2;
        font-size: 30px;
        font-weight: 500;
        color: #ff2836;

        &--active {
          color: #fff;
        }
      }
    }
  }
}

.filter-tabs {
  z-index: 4;
  position: fixed;
  top: 158px;
  left: 0;

  &::v-deep {
    .van-tabs__wrap {
      z-index: 3;
      width: 100vw;
      height: 94px;
      overflow: hidden;
      background: #fff;
    }

    .van-tabs__nav--line {
      padding-bottom: 0;

      .van-tabs__nav--complete {
        padding: 0 30px;
      }
    }

    .van-tab {
      align-items: flex-end;
      padding: 0 0 44px;
      width: 150px;
      font-size: 32px;
      font-weight: 500;
      color: #aaa;

      &--active {
        color: #ff1e32;
      }
    }

    .van-tabs__line {
      bottom: 20px;
      width: 14px !important;
      height: 14px;
      border-radius: 50%;
      background: #ff1e32;
    }
  }
}

.order {
  &-list {
    padding: 30px 30px 60px;
    width: 100%;
    background: #fff;
  }

  &-item {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-empty {
    @include flex-center-center;
    font-size: 32px;
    color: #8e8e8e;
  }
}
</style>
