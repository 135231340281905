<template>
  <van-list
    class="module-followerList"
    v-model="listLoading"
    :error.sync="listError"
    :finished="listFinished"
    error-text="请求失败，点击重新加载"
    finished-text="没有更多数据了"
    @load="handleListLoad"
  >
    <router-link
      :to="`/lottery/master?masterId=${item.id}&forecastType=${item.type}`"
      class="follower-item"
      v-for="item in followerList"
      :key="item.id"
    >
      <div class="follower-container">
        <img class="follower-face" :src="item.face" />
        <p class="follower-name">{{ item.name }}</p>
      </div>
      <div :class="`follower-link${item.new ? '_new' : ''}`">
        <template v-if="item.new">
          {{ item.type | getForecastText }}预测
        </template>
        <template v-else>未发布预测</template>
      </div>
    </router-link>
  </van-list>
</template>

<script>
export default {
  data() {
    return {
      listLoading: false,
      listError: false,
      listFinished: false,
      listPage: 1,
      listLastPage: 0,
      followerList: []
    };
  },
  methods: {
    handleListLoad() {
      if (this.listLastPage && this.listPage > this.listLastPage) return;
      this.$http(
        'followers/index',
        { page: this.listPage, pageSize: 30 },
        { loading: false }
      )
        .then(res => {
          this.setMasterList(res.data);
          this.listPage += 1;
          this.listLastPage = res.lastPage;
          this.listFinished = res.currentPage === res.lastPage;
        })
        .catch(err => {
          this.listError = true;
          throw new Error(err);
        })
        .finally(() => (this.listLoading = false));
    },

    setMasterList(data) {
      this.followerList = data.map(e => {
        return {
          id: e.followingId,
          name: e.nickName,
          face: e.avatarUrl,
          type: e.type,
          new: e.predict
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.module-followerList {
  padding: 0 0 30px;
}

.follower {
  &-item {
    @include flex-between-center;
    padding: 0 40px 0 40px;
    width: 100%;
    height: 156px;
    border-radius: 16px;
    background: #fff;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 30px;
      width: 690px;
      height: 1px;
      background: #eaeaea;
      transform: scaleY(-50%);
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &-container {
    @include align-center;
  }

  &-face {
    margin-right: 30px;
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }

  &-name {
    @include text-ellipsis;
    max-width: 292px;
    font-size: 36px;
    font-weight: 500;
  }

  &-link {
    @include flex-center-center;
    width: 200px;
    height: 64px;
    border-radius: 32px;
    font-size: 28px;
    color: #a9a9a9;
    background: #f5f5f5;

    &_new {
      @extend .follower-link;
      color: #e53400;
      background: #ffe4b4;
    }
  }
}
</style>
