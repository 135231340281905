<template>
  <van-popup class="module-success" :value="show">
    <div class="success-wrap">
      <img class="success-img" src="~image/user/register/success.png" alt="" />
      <p class="success-text">
        提交申请成功，您可以在个人中心随时查看审核状态，请耐心等候审核，感谢您的加入。
      </p>
      <div class="success-btn" @click="handleConfirmClick">好的</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    handleConfirmClick() {
      this.$emit('update:show', false);
      this.$router.replace('/user');
    }
  }
};
</script>

<style lang="scss" scoped>
.module-success {
  overflow: initial;
  background: transparent;
}

.success {
  &-wrap {
    width: 542px;
    border-radius: 16px;
    overflow: hidden;
    background: #fff;
  }

  &-img {
    width: 100%;
    height: 120px;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }

  &-text {
    padding: 30px;
    width: 100%;
    line-height: 48px;
    text-align: center;
    font-size: 36px;
    text-align: center;
    color: #232323;
  }

  &-btn {
    @include flex-center-center;
    @include hairlines(top, #d1d1d1);
    width: 100%;
    height: 98px;
    border-radius: 0 0 16px 16px;
    font-size: 34px;
    color: #040404;
  }
}
</style>
