<template>
  <div class="module-serviceItem" v-if="serviceData">
    <div class="service-container">
      <img class="service-bg" :src="serviceData.bgImg" />
      <slot></slot>
      <div class="service-title">
        <span class="service-name">{{ serviceData.name }}</span>
        <span>会员</span>
      </div>
      <p class="service-text">{{ serviceData.text }}</p>
      <slot name="tip">
        <p class="service-tip">
          {{ serviceData.tip }}
        </p>
      </slot>
    </div>
    <slot name="after"></slot>
  </div>
</template>

<script>
import itemBgByYear from 'image/user/service/item-bg_year.png';
import itemBgByMonth from 'image/user/service/item-bg_month.png';
import itemBgByQuarter from 'image/user/service/item-bg_quarter.png';
import itemBgByWeek from 'image/user/service/item-bg_week.png';
import itemBgByDay from 'image/user/service/item-bg_day.png';
import itemBgByVip from 'image/user/service/item-bg_vip.png';

export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(data) {
        this.setServiceData(data);
      }
    }
  },
  data() {
    return {
      serviceData: null,
      serviceTypeData: {
        day: {
          tip: '为期24小时',
          text: '以开卡时间为起点，延续24小时。',
          bgImg: itemBgByDay
        },
        week: {
          tip: '为期7天',
          text: '以开卡日期起算的7天内，可免费查阅本平台奖师榜预测码。',
          bgImg: itemBgByWeek
        },
        month: {
          tip: '为期30天',
          text: '以开卡时间为起点，延续一个自然月。',
          bgImg: itemBgByMonth
        },
        quarter: {
          tip: '为期90天',
          text: '以开卡时间为起点，延续一个自然季度。',
          bgImg: itemBgByQuarter
        },
        year: {
          tip: '为期12个月',
          text: '以开卡日期起算的365天内，可免费查阅本平台奖师榜预测码。',
          bgImg: itemBgByYear
        },
        vip: {
          tip: '为期30天',
          text: '以开卡日期起算的一个自然月内，可享受平台智能奖师推荐，及查阅所有奖师的智能分析回报数据。',
          bgImg: itemBgByVip
        }
      }
    };
  },
  methods: {
    setServiceData(data) {
      let typeData = this.serviceTypeData[data.type];
      this.serviceData = {
        id: data.id,
        type: data.type,
        name: data.serviceName,
        price: Number(data.money) || 0,
        tip: typeData.tip,
        text: typeData.text,
        bgImg: typeData.bgImg
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.module-serviceItem {
  width: 100%;
}

.service {
  &-container {
    z-index: 1;
    padding: 84px 36px 0;
    width: 100%;
    height: 382px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.28);
  }

  &-bg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
  }

  &-title {
    display: flex;
    align-items: baseline;
    margin: 0 0 28px 0;
    font-size: 32px;
    font-weight: 500;
    color: #fff;
    text-shadow: 0px 2px 4px #00295b;
  }

  &-name {
    margin-right: 17px;
    font-size: 69px;
  }

  &-icon {
    margin-left: 11px;

    &_day,
    &_week,
    &_month,
    &_quarter,
    &_yaer {
      @extend .service-icon;
      width: 66px;
      height: 34px;
      background: url(~image/user/service/vip.png) 0 0 / cover no-repeat;
    }

    &_vip {
      @extend .service-icon;
      width: 58px;
      height: 40px;
      background: url(~image/user/service/superVip.png) 0 0 / cover no-repeat;
    }
  }

  &-text {
    margin-bottom: 30px;
    line-height: 36px;
    font-size: 28px;
    color: #0d0d0d;
  }

  &-tip {
    font-size: 26px;
    color: #0d0d0d;
  }
}
</style>
