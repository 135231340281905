<template>
  <div class="page-register">
    <div class="header">
      <img class="header-logo" src="~image/user/register/logo.png" />
    </div>
    <div class="form">
      <div class="form-item">
        <div class="item-input">
          <input
            v-model="name"
            maxlength="10"
            type="text"
            placeholder="请输入姓名"
            @input="handleInputInput('name')"
            @focus="handleInputFocus('name')"
            @blur="handleInputBlur('name')"
          />
        </div>
        <app-icon
          v-show="nameCloseShow"
          class="item-close"
          name="close"
          size="35"
          @click.native="handleCloseClick('name')"
        ></app-icon>
      </div>
      <div class="form-item">
        <div class="item-input">
          <input
            v-model="phone"
            class="item-input"
            type="text"
            maxlength="11"
            placeholder="请输入手机号码"
            inputmode="numeric"
            @input="handleInputInput('phone')"
            @focus="handleInputFocus('phone')"
            @blur="handleInputBlur('phone')"
          />
        </div>
        <app-icon
          v-show="phoneCloseShow"
          class="item-close"
          name="close"
          size="35"
          @click.native="handleCloseClick('phone')"
        ></app-icon>
      </div>
    </div>
    <div
      :class="`submit${!this.name || !this.phone ? '_disabled' : ''}`"
      @click="handleSubmit"
    >
      注册分销商
    </div>
    <div class="check">
      <van-checkbox
        v-model="checked"
        icon-size="4.267vw"
        checked-color="#FF712A"
      >
        <span>我已阅读并同意</span>
        <router-link class="check-link" to="/distribution/about">
          《分销商经营规范》</router-link
        >
      </van-checkbox>
    </div>
    <module-success :show="successShow"></module-success>
  </div>
</template>

<script>
import moduleSuccess from './components/moduleSuccess.vue';

export default {
  components: {
    moduleSuccess
  },
  data() {
    return {
      checked: false,
      successShow: false,
      nameCloseShow: false,
      phoneCloseShow: false,
      name: '',
      phone: ''
    };
  },
  methods: {
    verification() {
      if (!this.name) {
        return '请输入姓名';
      }
      if (!this.phone) {
        return '请输入手机号码';
      }
      if (this.phone.length !== 11) {
        return '请输入完整的手机号码';
      }
      if (!this.checked) {
        return '请先阅读并同意使用推广协议';
      }
      return '';
    },

    handleInputFocus(type = '') {
      if (this[type]) this[`${type}CloseShow`] = true;
    },

    handleInputInput(type = '') {
      if (this[type]) this[`${type}CloseShow`] = true;
    },

    handleInputBlur(type = '') {
      this[type] = this[type].trim();
      setTimeout(() => {
        if (this[type]) this[`${type}CloseShow`] = false;
      }, 10);
    },

    handleCloseClick(type = '') {
      this[type] = '';
      this[`${type}CloseShow`] = false;
    },

    handleSubmit() {
      let verify = this.verification();
      if (verify) {
        return this.$toast(verify);
      }
      this.$http('superior/apply', {
        name: this.name,
        mobile: this.phone
      }).then(() => {
        this.successShow = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-register {
  padding: 173px 0 0;
}

.header {
  @include flex-center-center;
  flex-direction: column;
  margin-bottom: 127px;

  &-logo {
    width: 320px;
    height: 142px;
  }
}

.form {
  &-item {
    @include align-center;
    margin: 0 auto 30px;
    padding: 0 20px 0 30px;
    width: 630px;
    height: 98px;
    border-radius: 24px;
    background: #f9f9f9;

    .item {
      &-input {
        @include align-center;
        flex: 1;
        height: 100%;
        font-size: 28px;

        input {
          width: 520px;
        }
      }

      &-close {
        position: absolute;
        top: 31px;
        right: 20px;
      }
    }
  }
}

.check {
  margin-top: 40px;
  &::v-deep {
    .van-checkbox {
      justify-content: center;
    }

    .van-checkbox__label {
      margin-top: 2px;
    }
  }

  .check {
    &-link {
      display: inline;
      color: #4488ff;
    }
  }
}

.submit {
  @include flex-center-center;
  margin: 140px auto 0;
  width: 630px;
  height: 98px;
  border-radius: 24px;
  font-size: 36px;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
  box-shadow: 0px 8px 0px 0px #ffc6b5;

  &_disabled {
    @extend .submit;
    color: #b6b6b6;
    background: linear-gradient(90deg, #f1f1f1 0%, #dedede 100%);
    box-shadow: none;
  }
}
</style>
