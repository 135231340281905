<template>
  <div class="service-list">
    <img class="list-bg" src="~image/user/service/list-bg.png" />
    <service-item
      v-for="item in serviceList"
      :key="item.id"
      class="service-item"
      :data="item"
    >
      <div class="service-pay">
        <img class="pay-img" src="~image/user/service/price-img.png" />
        <div class="service-price">
          <div class="price">
            <p class="price-text_max" :data-text="item.money.split('.')[0]">
              {{ item.money.split('.')[0] }}
            </p>
            <p
              class="price-text"
              :data-text="
                item.money.split('.')[1] ? `.${item.money.split('.')[1]}` : ''
              "
            >
              {{
                item.money.split('.')[1] ? `.${item.money.split('.')[1]}` : ''
              }}
            </p>
          </div>
          <p class="origin">原价：{{ item.lineMoney }}</p>
        </div>
        <div
          :class="`pay-btn_${item.type}`"
          :style="{
            background: `url(${item.btnBg}) 0 0 / contain no-repeat`
          }"
          @click="handleServiceBtnClick(item)"
        ></div>
      </div>
      <template v-slot:tip><p></p></template>
    </service-item>
    <router-link to="/user/servicePage" class="service-banner"></router-link>
    <pay-popup
      ref="payPopup"
      :show.sync="payPopupShow"
      :content="payData.content"
      :price="payData.price"
      @confirm="handlePayConfirm"
    ></pay-popup>
  </div>
</template>

<script>
import payPopup from '@/components/payPopup';
import serviceItem from '@/components/serviceItem';
import itemBtnBgByYear from 'image/user/service/item-btn_year.png';
import itemBtnBgByMonth from 'image/user/service/item-btn_month.png';
import itemBtnBgByWeek from 'image/user/service/item-btn_week.png';
import itemBtnBgByDay from 'image/user/service/item-btn_day.png';
import itemBtnBgByQuarter from 'image/user/service/item-btn_quarter.png';

export default {
  components: {
    serviceItem,
    payPopup
  },
  data() {
    return {
      userId: '',
      serviceList: [],
      payPopupShow: false,
      payData: {
        id: 0,
        content: '',
        price: ''
      },
      itemBtnBgByYear,
      itemBtnBgByMonth,
      itemBtnBgByWeek,
      itemBtnBgByDay,
      itemBtnBgByQuarter
    };
  },
  methods: {
    getServiceList() {
      this.$http('service/index', { page: 1, pageSize: 999 }).then(res => {
        this.serviceList = res.map(e => {
          e.money = `${Number(e.money)}`;
          e.lineMoney = `${Number(e.lineMoney)}`;
          e.type = e.typeKey;
          e.serviceName = e.name;
          e.btnBg =
            this[
              `itemBtnBgBy${e.typeKey.replace(/^\S/, s => s.toUpperCase())}`
            ];
          return e;
        });
      });
    },

    handleServiceBtnClick(item) {
      this.payData.id = item.id;
      this.payData.price = item.money;
      this.payData.content = `开彩乐-${item.name}会员`;
      this.payPopupShow = true;
    },

    async handlePayConfirm(payType) {
      try {
        let data = { serviceId: this.payData.id, payType };
        let res = await this.$http(
          'orderService/add',
          this.$util.transformKey(data)
        );
        if (payType === 2) {
          this.$refs.payPopup.updateUserMoney(res);
          return this.handlePaySuccess();
        }
        await this.$wx
          .pay(res)
          .then(money => {
            this.handlePaySuccess(money);
          })
          .catch(() => this.handlePayFail());
      } catch (error) {
        this.handlePayFail(JSON.parse(error.message));
      }
    },

    handlePaySuccess() {
      this.$dialog
        .confirm({
          title: '购买成功',
          message: '恭喜您成为我们的会员，马上回首页浏览奖师预测吧！',
          cancelButtonText: '再看看',
          confirmButtonText: '回首页'
        })
        .then(() => {
          this.$router.replace('/');
        })
        .catch(() => {
          this.payPopupShow = false;
        });
    },

    handlePayFail(err) {
      let config = {
        title: (err && '购买失败') || '',
        message: (err && err.message) || '购买失败'
      };
      this.$dialog.alert(config);
    }
  },
  created() {
    this.getServiceList();
  }
};
</script>

<style lang="scss" scoped>
.service {
  &-list {
    padding: 462px 30px 148px;
    min-height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(255, 232, 200, 0.42) 0%,
      rgba(255, 230, 201, 0.82) 70%,
      #fff5eb 100%
    );

    .list-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 750px;
      height: 489px;
    }
  }

  &-item {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &::v-deep {
      .service-text {
        margin-top: 92px;
        width: 392px;
        line-height: 40px;
      }
    }
  }

  &-pay {
    @include flex-center-center;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 20px;
    width: 234px;

    .pay {
      &-img {
        margin: 0 auto;
        width: 169px;
        height: 40px;
      }

      &-btn {
        @include flex-center-center;
        z-index: 1;
        margin: 0 auto;
        width: 204px;
        height: 84px;

        &_day,
        &_month,
        &_week,
        &_quarter,
        &_year {
          @extend .pay-btn;
        }
      }
    }
  }

  &-price {
    @include align-center;
    flex-direction: column;

    .price {
      @include justify-center;
      align-items: baseline;

      &-text {
        font-size: 72px;
        font-weight: 600;
        color: #fff;
        background: linear-gradient(180deg, #ffffff 0%, #fff275 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;

        &::before {
          content: attr(data-text);
          position: absolute;
          z-index: -1;
          text-shadow: 4px 3px 0px rgba(211, 49, 0, 0.87);
        }

        &_max {
          @extend .price-text;
          font-size: 120px;
        }
      }
    }

    .origin {
      margin: -14px 0 7px;
      font-size: 28px;
      color: #81000e;
      text-decoration: line-through;
    }
  }

  &-banner {
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 108px;
    background: url(~image/user/service/banner_superVip.png) 0 0 / cover
      no-repeat;
  }
}
</style>
