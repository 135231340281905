<template>
  <!-- 个人信息编辑 -->
  <div class="form">
    <div class="form-item">
      <p class="item-label">头像：</p>
      <div class="item-value">
        <app-icon name="touxiang" size="98"></app-icon>
      </div>
    </div>
    <div class="form-item">
      <p class="item-label">昵称：</p>
      <div class="item-value">
        <input class="item-input" type="text" placeholder="请输入昵称" />
      </div>
    </div>
    <div class="form-item">
      <p class="item-label">性别：</p>
      <div class="item-value_select">
        <input
          class="item-input"
          type="text"
          readonly
          placeholder="请选择性别"
          @click="showPicker = true"
          :value="value"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </div>
    </div>
    <div class="form-item">
      <p class="item-label">绑定手机号：{{ 15500001111 }}</p>
      <router-link class="item-link" to="/distribution/register"
        >修改手机号</router-link
      >
    </div>
    <div class="form-submit">保存并提交</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      showPicker: false,
      columns: ['男', '女']
    };
  },
  methods: {
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  $placeholderColor: #bfbfbf;

  ::-webkit-input-placeholder {
    color: $placeholderColor;
  }

  &-item {
    @include hairlines(bottom, #eee);
    @include flex-between-center;
    padding: 35px 30px 34px;
    min-height: 120px;
    font-size: 32px;

    .item {
      &-value {
        &_select {
          @include align-center;
          @include arrow(16px, right, #888);

          &::after {
            margin-left: 9px;
          }
        }
      }

      &-input {
        text-align: right;
      }

      &-link {
        color: $placeholderColor;
        font-size: 32px;
      }
    }
  }

  &-submit {
    @include flex-center-center;
    position: fixed;
    bottom: 40px;
    left: 76px;
    width: 598px;
    height: 88px;
    border-radius: 44px;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(180deg, #fea06a 0%, #fd4a26 100%);
    box-shadow: 0px 4px 16px 0px rgba(255, 40, 0, 0.3);
  }
}
</style>
