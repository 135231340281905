<template>
  <div class="order" v-if="orderData">
    <order-detail class="order-container" :lotteryData="forecastData">
      <template v-slot:master>
        <div class="order-master">
          <img
            class="master-face"
            :src="orderData.forecast.user.avatarUrl"
            v-if="orderData.forecast.user.avatarUrl"
          />
          <app-icon
            name="touxiang"
            size="98"
            class="master-face"
            v-else
          ></app-icon>
          <p class="master-name">{{ orderData.forecast.user.nickName }}</p>
        </div>
      </template>
    </order-detail>
    <div class="order-info">
      <p class="info-item">
        <span class="info-label">交易单号：</span>
        <span>{{ orderData.orderNo }}</span>
      </p>
      <p class="info-item">
        <span class="info-label">交易时间：</span>
        <span>{{
          (orderData.payTime * 1000) | dateFormat('YYYY/MM/DD HH:mm:ss')
        }}</span>
      </p>
      <p class="info-item">
        <span class="info-label">购买内容：</span>
        <span>付款预测(排列五：第{{ orderData.no }}期)</span>
      </p>
      <p class="info-item">
        <span class="info-label">支付金额：</span>
        <span>¥ {{ Number(orderData.price) || 0 }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import orderDetail from '@/components/lotteryItem';

export default {
  components: {
    orderDetail
  },
  data() {
    return {
      orderId: '',
      forecastData: null,
      orderData: null
    };
  },
  methods: {
    getOrderData() {
      this.$http('order/info', { id: this.orderId }).then(res => {
        let { forecast } = res;
        this.forecastData = {
          id: forecast.id,
          no: res.no,
          date: forecast.date,
          forecastList:
            forecast.type !== 10
              ? JSON.parse(forecast.map)
              : [
                  forecast.position1,
                  forecast.position2,
                  forecast.position3,
                  forecast.position4,
                  forecast.position5
                ],
          forecastType: forecast.type || 10,
          forecastStatus: forecast.status,
          rightNumber: forecast.rightNumber,
          checkNum: forecast.checkNum
        };
        this.orderData = res;
      });
    }
  },
  async created() {
    this.orderId = this.$route.query.orderId;
    if (!this.orderId) return;
    this.getOrderData();
  }
};
</script>

<style lang="scss" scoped>
.order {
  padding: 62px 30px;
  background: linear-gradient(180deg, #ff925e 0%, #ff4019 100%) top left/ 100vw
    320px no-repeat;
  &-master {
    @include align-center;
    z-index: 2;
    padding: 38px $lotteryItem-paddingX;
    border-radius: 0 0 16px 16px;
    font-size: 32px;
    font-weight: 500;
    color: #4d4d4d;
    background: #fff;

    .master {
      &-face {
        margin-right: 40px;
        width: 98px;
        height: 98px;
        border-radius: 50%;
      }
    }
  }

  ::v-deep .lottery-result {
    border-radius: 20px;
  }

  &-header {
    @include flex-between-center;
    z-index: 2;
    padding: 30px $lotteryItem-paddingX;
    border-radius: 20px;
    line-height: 52px;
    font-size: 28px;
    background: #fff;

    .result {
      &-time,
      &-issue,
      &-info {
        flex: 1;
        min-height: 104px;
      }

      &-issue {
        text-align: center;
      }

      &-info {
        text-align: right;

        &_text {
          font-weight: 500;
          color: #fe9139;
        }

        &_img {
          z-index: 3;
          position: absolute;
          top: 20px;
          right: 0;
          width: 206px;
          height: 169px;
        }
      }
    }
  }

  &-info {
    margin: 40px auto 0;
    padding: 40px 30px;
    width: 100%;
    border-radius: 16px;
    font-size: 28px;
    color: #4d4d4d;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.24);

    .info {
      &-item {
        @include flex-between-center;
        margin-bottom: 30px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &_label {
        color: #999;
      }
    }
  }
}
</style>
