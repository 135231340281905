<template>
  <div class="page-seviceOrderList">
    <div class="service-list" v-show="this.serviceList.length">
      <router-link
        v-for="item in serviceList"
        :key="item.id"
        :class="`service-item${isOverdue ? '_overdue' : ''}`"
        :to="`/user/serviceOrder?orderId=${item.id}`"
      >
        <service-item :data="item">
          <template v-slot:tip>
            <p class="service-limit">
              有效期：{{ item.beginTime | dateFormat('YYYY/MM/DD HH:mm') }} -
              {{ item.endTime | dateFormat('YYYY/MM/DD HH:mm') }}
            </p>
          </template>
        </service-item>
      </router-link>
      <router-link
        v-show="!isOverdue"
        class="service-overdue"
        to="/user/serviceOrderListOverdue"
        >查看我的过期服务</router-link
      >
    </div>
    <div class="empty" v-show="emptyShow">
      <img
        :class="`empty-img${isOverdue ? '_overdue' : ''}`"
        :src="isOverdue ? emptyOverdueImg : emptyImg"
      />
      <p class="empty-text">
        {{ isOverdue ? '没有找到已过期的服务' : '您还未开通会员服务' }}
      </p>
      <router-link v-show="!isOverdue" class="empty-btn" to="/user/serviceList"
        >现在去开通</router-link
      >
    </div>
  </div>
</template>

<script>
import serviceItem from '@/components/serviceItem';
import emptyImg from 'image/user/service/empty.png';
import emptyOverdueImg from 'image/user/service/empty_overdue.png';

export default {
  components: {
    serviceItem
  },
  data() {
    return {
      emptyImg,
      emptyOverdueImg,
      isOverdue: false,
      emptyShow: false,
      serviceList: []
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.serviceList = [];
        this.emptyShow = false;
        this.isOverdue = to.name === 'serviceOrderListOverdue';
        this.getServiceList();
      }
    }
  },
  methods: {
    getServiceList() {
      this.$http('orderService/index', {
        page: 1,
        pageSize: 999,
        type: this.isOverdue ? 2 : 1
      }).then(res => {
        this.serviceList = res.data.map(e => {
          e.type = e.serviceType;
          return e;
        });
        if (!res.data.length) this.emptyShow = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-seviceOrderList {
  min-height: 100vh;
  background: #f6f6f6;
}

.service {
  &-list {
    padding: 30px;
  }

  &-item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &_overdue {
      @extend .service-item;

      & .module-serviceItem {
        opacity: 0.4;
      }

      &::before {
        content: '';
        z-index: 1;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 195px;
        height: 192px;
        background: url(~image/user/service/end.png) 0 0 / cover no-repeat;
      }
    }
  }

  &-limit {
    margin-bottom: 50px;
    font-size: 24px;
  }

  &-overdue {
    @include flex-center-center;
    margin-top: 60px;
    color: #999;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 32px;
      height: 2px;
      border-top: solid 2px #c9c9c9;
    }

    &::before {
      margin-right: 18px;
    }

    &::after {
      margin-left: 18px;
    }
  }
}

.qrcode {
  margin: -14px 0 0;
  padding: 30px 36px 0 31px;
  width: 100%;
  height: 90px;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
  background: #fff;
  transition: all 0.3s;

  &_show {
    @extend.qrcode;
    height: 742px;
  }

  &-header {
    @include flex-between-center;
  }

  &-title {
    @include align-center;
    font-size: 28px;
    color: #a9a9a9;

    .title-text {
      margin-left: 12px;
    }
  }

  &-showBtn {
    @include align-center;
    @include arrow(16px, bottom, #909090);
    font-size: 28px;
    color: #a9a9a9;

    &::after {
      margin: -6px 0 0 12px;
      transition: all 0.3s;
    }

    &_show {
      @extend .qrcode-showBtn;
      @include arrow(16px, top, #909090);

      &::after {
        margin: 12px 0 0 12px;
      }
    }
  }

  &-container {
    @include align-center;
    flex-direction: column;
    padding: 36px 0 27px;
  }

  &-code {
    margin-bottom: 28px;
    font-size: 42px;
    font-weight: 500;
    color: #337bff;
  }

  &-copy {
    @include flex-center-center;
    margin-bottom: 44px;
    width: 240px;
    height: 81px;
    border-radius: 24px;
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    background: #fb9938 linear-gradient(90deg, #94b3ff 0%, #5b8aff 100%);
  }

  &-img {
    margin-bottom: 33px;
    width: 280px;
    height: 280px;
  }

  &-text {
    font-size: 28px;
    text-align: center;
    color: #bdbdbd;
    line-height: 42px;
  }
}

.empty {
  @include flex-center-center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);

  &-img {
    margin-bottom: 60px;
    width: 404px;
    height: 404px;

    &_overdue {
      @extend .empty-img;
      width: 404px;
      height: 404px;
    }
  }

  &-text {
    margin-bottom: 40px;
    font-size: 32px;
    color: #8e8e8e;
  }

  &-btn {
    @include flex-center-center;
    width: 290px;
    height: 98px;
    border-radius: 24px;
    font-size: 36px;
    color: #fff;
    background: #fb9938 linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
    box-shadow: 0px 8px 0px 0px #ffaf96;
  }
}
</style>
